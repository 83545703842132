




































import { Component, Vue } from 'vue-property-decorator'
import ws, { SocketMsg } from '../../services/socket'
import settingService from '@/services/settingService'
import { GroupedData, PriceMsgContent, PortfolioGroup, OptionMsgContent } from '@/services/data'
import PortfolioList from '@/components/PortfolioList.vue'
import portfolioService from '@/services/portfolioService'
import store from '@/store'
import ColumnSelector from '@/components/ColumnsSelector.vue'
import { calcStrikePro, setPriceValues } from '@/services/func'

@Component({
  components: {
    PortfolioList,
    ColumnSelector
  }
})
export default class Portfolio extends Vue {
  groups: GroupedData<PortfolioGroup>[] = []
  currentGroupKeys: string[] = []
  currentDefaultKeys: string[] = []
  currentSecType: string
  colSettingDialog = false
  tradingParams = 0
  account = ''
  showAgentDownloading = false
  errorMsg = ''
  loadingData = false
  isAgentPortfolioRead = false
  readFromAgent = false

  async mounted () {
    ws.Local.init()
    ws.Server.init()
    const tp = await settingService.readUserSettingsByNamesAsync(['StrikeProxPercent'])
    if (tp.Result && tp.Result.length > 0) {
      const tpv = parseInt(tp.Result[0].Value)
      if (!isNaN(tpv)) {
        this.tradingParams = tpv
      }
    }
    await this.loadPortfolios()
    ws.Local.addMessageCallBack(this.agentMsgCallBack)
    ws.Server.addMessageCallBack(this.serverMsgCallBack)
  }

  async loadPortfolios () {
    this.loadingData = true
    this.errorMsg = null
    const res = await portfolioService.getPortfoliosGroups()
    if (res.Error) {
      if (res.Error === 'READ_FROM_AGENT') { // server response indicate to load data from agent
        ws.Local.sendDirectMsg('reqPortfolios', { uid: store.state.user.uid })
        this.readFromAgent = true
      } else {
        this.errorMsg = (this.$t(res.Error)).toString()
      }
      this.loadingData = false
      return []
    }
    this.readFromAgent = false
    await this.showData(res.Result)
    return res.Result
  }

  async showData (data: GroupedData<PortfolioGroup>[]) {
    for (const secGroup of data) {
      for (const group of secGroup.Items) {
        await this.calcRowValues(group)
        for (const subItem of group.Items) {
          calcStrikePro(subItem, this.tradingParams)
        }
      }
    }
    this.groups = data
    if (data && data.length > 0) this.account = data[0].Items[0].Items[0].AccountName
    for (const item of this.groups) {
      if (item.Keys == null || item.Keys.length === 0) {
        item.Keys = await this.getKeysAsync(item.SecType)
      }
    }
    ws.SendAll({ page: 'portfolio', symbols: this.getAllSymbolIds(data), options: this.getAllOptionIds(data) })
    this.loadingData = false
  }

  get userAccNo () {
    return store.state.user.accNo
  }

  get agent () {
    return store.state.agent
  }

  destroyed (): void {
    ws.Local.removeMessageCallBack(this.agentMsgCallBack)
    ws.Server.removeMessageCallBack(this.serverMsgCallBack)
  }

  async calcRowValues (newGroup: PortfolioGroup) {
    for (const newItem of newGroup.Items) {
      if (newItem.Position !== 0) {
        newItem.Return = newItem.UnrealizedPL / Math.abs(newItem.AverageCost * newItem.Position)
        if (newItem.SecType === 'STK') {
          if (newItem.StockPrice) {
            newItem.Change = newItem.StockPrice.getChange()
            newItem.ChangeRate = newItem.StockPrice.getChangeRate()
            if (newItem.Change != null) {
              newItem.DailyPL = newItem.Change * newItem.Position
            }
          }
        }
      } else {
        newItem.UnrealizedPL = 0
        newItem.Return = 0
      }
    }
  }

  showColumnDialog (group: GroupedData<PortfolioGroup>) {
    this.colSettingDialog = true
    this.currentDefaultKeys = this.getDefaultKeys(group.SecType)
    this.currentGroupKeys = group.Keys
    this.currentSecType = group.SecType
  }

  async saveColumnKeys (keys: string[]) {
    if (keys.length === 0) return
    await settingService.addOrUpdateUserSettingAsync([{
      Name: this.currentSecType + 'Columns',
      Value: JSON.stringify(keys),
      ValueType: 'Json'
    }])
    const findGroup = this.groups.find(p => p.SecType === this.currentSecType)
    if (findGroup) {
      findGroup.Keys = keys
    }
  }

  async serverMsgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resPortfolioUpdated') { // Server ibkr gateway portfolios changed
      await this.loadPortfolios()
    } else if (msg.Action === 'resPortfolios') { // response portfolio data from server which is sent from agent
      if (msg.Content.Error) {
        await this.$alert(msg.Content.Error)
        return
      }
      this.isAgentPortfolioRead = true
      await this.showData(portfolioService.convertGroupRes(msg.Content))
      this.errorMsg = ''
    } else if (msg.Action === 'resOpt') {
      for (const secGroup of this.groups) {
        if (secGroup.SecType === 'OPT') {
          const content = msg.Content as OptionMsgContent
          for (const group of secGroup.Items) {
            for (const subItem of group.Items) {
              if (subItem.OptionData?.OptionUId === msg.MsgId) {
                if (content.Name === 'OptPrice') {
                  subItem.MarketPrice = content.Value
                  subItem.MarketValue = subItem.Position * subItem.OptionData.Multiplier * content.Value
                  subItem.UnrealizedPL = (content.Value * subItem.OptionData.Multiplier - subItem.AverageCost) * subItem.Position
                }
                continue
              }
            }
          }
        }
      }
    } else if (msg.Action === 'resStk') {
      const content = msg.Content as PriceMsgContent
      this.updateStkRow(msg.MsgId, content)
    }
  }

  updateStkRow (uid: number, content: PriceMsgContent) {
    if (content.Value != null) {
      const stk = this.groups.find(p => p.SecType === 'STK')
      if (stk) {
        const portfolioGroup = stk.Items.find(p => p.Symbol.SymbolUId === uid)
        if (portfolioGroup) {
          for (const portfolioRow of portfolioGroup.Items) {
            if (portfolioRow.StockPrice) {
              setPriceValues(content, portfolioRow.StockPrice)
              calcStrikePro(portfolioRow, this.tradingParams)
              if (content.Name === 'Last') {
                // console.log(`${portfolioRow.Symbol.Symbol} new price is: ${content.Value}`)
                portfolioRow.MarketValue = portfolioRow.Position * content.Value
                portfolioRow.UnrealizedPL = (content.Value - portfolioRow.AverageCost) * portfolioRow.Position
              }
              portfolioRow.Change = portfolioRow.StockPrice.getChange()
              portfolioRow.ChangeRate = portfolioRow.StockPrice.getChangeRate()
              if (portfolioRow.Change != null) {
                portfolioRow.DailyPL = portfolioRow.Change * portfolioRow.Position
              }
            }
          }
        }
      }
    }
  }

  agentMsgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resPortfolioUpdated') { // trading agent gateway portfolios changed
      ws.Local.sendDirectMsg('reqPortfolios', { uid: store.state.user.uid })
    } else if (msg.Action === 'resAgentStatus') {
      if (!this.loadingData) {
        if (!this.isAgentPortfolioRead) {
          ws.Local.sendDirectMsg('reqPortfolios', { uid: store.state.user.uid })
        }
      }
    } else if (msg.Action === 'resStk') {
      const content = msg.Content as PriceMsgContent
      this.updateStkRow(msg.MsgId, content)
    }
  }

  getAllSymbolIds (data: GroupedData<PortfolioGroup>[]) {
    const arr: number[] = []
    data.forEach(p => p.Items.forEach(q => {
      arr.push(q.Symbol.SymbolId)
    }))
    return arr
  }

  getAllOptionIds (data: GroupedData<PortfolioGroup>[]) {
    const arr: number[] = []
    data.forEach(p => p.Items.forEach(q => {
      q.Items.forEach(x => {
        if (x.OptionData != null) {
          arr.push(x.OptionData.OptionId)
        }
      })
    }))
    return arr
  }

  getDefaultKeys (type: string) {
    if (type === 'OPT') {
      return ['Strike', 'ExpirationDate', 'Right', 'Multiplier', 'AverageCost', 'NextEarnDate', 'ComboType', 'StrikeProx', 'Exposure', 'Position', 'MarketPrice', 'MarketValue', 'Price', 'UnrealizedPL', 'Return', 'Currency', 'ExchRate']
    } else if (type === 'STK') {
      return ['Change', 'ChangeRate', 'Position', 'AverageCost', 'Volume', 'AverageVol', 'NextEarnDate', 'MarketPrice', 'DailyPL', 'MarketValue', 'UnrealizedPL', 'Return', 'Currency', 'ExchRate']
    } else if (type === 'BOND') {
      return ['ISIN', 'Position', 'MarketValue', 'Coupon', 'MaturityDate', 'LastTradePrice', 'YTM', 'CouponDate', 'Frequency', 'Currency']
    } else {
      return ['Position', 'MarketPrice', 'MarketValue', 'Change', 'Return']
    }
  }

  async getKeysAsync (name: string): Promise<string[]> {
    try {
      const res = await settingService.readUserSettingsByNamesAsync([name + 'Columns'])
      if (res.Result != null && res.Result.length > 0) {
        const arr = JSON.parse(res.Result[0].Value) as string[]
        const defArr = this.getDefaultKeys(name)
        arr.forEach((item, index) => {
          if (defArr.indexOf(item) < 0) {
            arr.splice(index, 1)
            index--
          }
        })
        return arr
      }
    } catch (e) {
    }
    return Promise.resolve(this.getDefaultKeys(name))
  }
}
