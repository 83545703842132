













































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ContractSymbol, GroupedData, PortfolioGroup, PortfolioMessage, PriceCls, StockPrice } from '@/services/data'
import utility from '@/common/utility'
import SetSymbolAlert from '@/components/SetAlertPrice.vue'
import SymbolName from '@/components/SymbolName.vue'

@Component({
  components: {
    SetSymbolAlert,
    SymbolName
  }
})

export default class PortfolioList extends Vue {
  name = 'PortfolioList'
  @Prop() GroupData: GroupedData<PortfolioGroup>
  @Prop() TradingParams: number
  alertPriceSettingDialog = false
  currentStock: StockPrice

  $num (num: number, digits = 2): string {
    if (num != null && num !== undefined) {
      return num.toLocaleString('en-US', { maximumFractionDigits: digits, minimumFractionDigits: digits })
    }
    return null
  }

  $fix (num: number, end?: string): string {
    if (num != null && num !== undefined) {
      return num.toFixed(2) + (end || '')
    }
    return null
  }

  sort (key: string, direction: string) {
    this.GroupData.Sort = { key, direction }
    const sort = (a: PortfolioGroup, b: PortfolioGroup): number => {
      const v = direction === 'Asc' ? 1 : -1
      const left = this.getByKey(a, key)
      const right = this.getByKey(b, key)
      if (left === right) return 0
      return left > right ? v : v * -1
    }
    if (sort) {
      this.GroupData.Items.sort(sort)
    }
  }

  setAlertPrice (symbol: ContractSymbol, item: PortfolioMessage) {
    this.currentStock = {
      Symbol: symbol,
      Price: new PriceCls({ Open: null, Close: item.StockPrice.Close, Last: item.MarketPrice })
    }
    this.alertPriceSettingDialog = true
  }

  alertAdded (res: {symbolId: number;count: number}) {
    this.GroupData.Items.forEach(q => {
      q.Items.forEach(p => {
        if (p.SecType === 'STK' && q.Symbol.SymbolId === res.symbolId) {
          p.Alert = res.count > 0
        }
      })
    })
  }

  get totalValue () {
    let sum = 0
    let valid = true
    this.GroupData.Items.forEach(q => {
      q.Items.forEach(p => {
        if (p.ExRate) {
          sum += Math.floor(p.MarketValue / p.ExRate)
        } else {
          valid = false
        }
      })
    })
    if (!valid) return 'EXCH.RATE'
    return sum.toFixed(0)
  }

  get totalDailyPL () {
    let sum = 0
    let valid = true
    this.GroupData.Items.forEach(q => {
      q.Items.forEach(p => {
        if (p.MarketValue && p.ExRate) {
          sum += Math.floor(p.DailyPL / p.ExRate)
        } else {
          valid = false
        }
      })
    })
    if (!valid) return 'EXCH.RATE'
    return sum
  }

  get totalExposure () {
    let sum = 0
    this.GroupData.Items.forEach(q => {
      q.Items.forEach(p => {
        if (p.MarketValue && p.ExRate) {
          sum += Math.floor(p.Exposure / p.ExRate)
        }
      })
    })
    return sum.toFixed(0)
  }

  get totalReturn () {
    let avg = 0
    let pl = 0
    let valid = true
    this.GroupData.Items.forEach(q => {
      q.Items.forEach(p => {
        if (!p.ExRate) {
          valid = false
        }
        if (p.AverageCost && p.UnrealizedPL && p.ExRate) {
          avg += p.AverageCost / p.ExRate * Math.abs(p.Position)
          pl += p.UnrealizedPL / p.ExRate
        }
      })
    })
    if (!valid || pl === 0 || avg === 0) {
      return null
    }
    return ((pl / avg) * 100)
  }

  getByKey = function (group: PortfolioGroup, key: string) {
    const item = group.Items[0]
    if (key === 'Price') {
      return item.StockPrice.getLast()
    } else if (key === 'Strike') {
      return item.OptionData.Strike
    } else if (key === 'ExpirationDate') {
      return item.OptionData.ExpirationDate
    } else if (key === 'Right') {
      return item.OptionData.Right
    } else if (key === 'Multiplier') {
      return item.OptionData.Multiplier
    } else if (key === 'Currency') {
      return group.Symbol.Currency
    } else if (key === 'ExchRate') {
      return item.ExRate
    } else if (key === 'NextEarnDate') {
      return item.StockPrice.NextEarnDate
    }
    return utility.getByKey(item, key)
  }
}
