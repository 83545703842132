import {
  ApiResult,
  ContractSymbol, GroupedData,
  PortfolioGroup,
  PortfolioGroupCls,
  PortfolioMessage,
  PriceCls
} from '@/services/data'
import ServiceBase from '@/services/serviceBase'

export default {
  async getPortfoliosGroups (): Promise<ApiResult<GroupedData<PortfolioGroup>[]>> {
    const secGroupedRes = await ServiceBase.tryPostResult<GroupedData<PortfolioGroup>[]>('/Portfolio/getPortfoliosGroups', {})
    this.convertGroupRes(secGroupedRes.Result)
    return secGroupedRes
  },
  convertGroupRes (result: GroupedData<PortfolioGroup>[]) {
    if (result) {
      for (const groupRes of result) {
        for (const group of groupRes.Items) {
          if (group.Symbol) {
            Object.freeze(group.Symbol)
          }
          group.Items.forEach(q => {
            q.StockPrice = new PriceCls(q.StockPrice)
            if (q.OptionData) {
              Object.freeze(q.OptionData)
            }
          })
        }
        for (let i = 0; i < groupRes.Items.length; i++) {
          groupRes.Items[i] = new PortfolioGroupCls(groupRes.Items[i])
        }
      }
    }
    return result
  }
}
